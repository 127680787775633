import { Routes } from '@angular/router';

export const cartRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./purchase').then(m => m.PurchaseComponent),
  },
  {
    path: 'confirmation',
    loadComponent: () => import('./order-confirmation').then(m => m.OrderConfirmationPageComponent),
  },
];
