import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Environment } from '@ev-portals/dp/frontend/shared/util';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'dp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  #environment = inject(Environment);
  @Input() apiHash: string | undefined;

  currentYear = new Date().getFullYear();
  stage = this.#environment.environment;
  appVersion = this.#environment.version;
  gitHash = this.#environment.hash;
}
