import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthenticationService } from '@ev-portals/dp/frontend/shared/auth/data-access';
import { LayoutService } from '@ev-portals/ev/frontend/util';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './profile-nav.component.html',
  styleUrls: ['./profile-nav.component.scss'],
})
export class ProfileNavComponent {
  #layoutService = inject(LayoutService);
  private authenticationService = inject(AuthenticationService);

  isSmallerThanMedium$ = this.#layoutService.isSmallerThanLarge$;
  user$ = this.authenticationService.user$;
  isAdmin$ = this.authenticationService.isAdmin$;

  onLogoutAttempt(): void {
    // TODO: add later
    // this.authenticationService
    //   .logout()
    //   .subscribe(this.authenticationService.logoutHandler);
  }
}
