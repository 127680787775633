import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@ev-portals/dp/frontend/shared/util';
import { ErrorPageComponent } from '@ev-portals/ev/frontend/ui-library';

@Component({
  standalone: true,
  imports: [ErrorPageComponent],
  templateUrl: './page-not-found.component.html',
  styleUrls: [],
})
export class PageNotFoundComponent {
  statusNotFound = '404 Page not found.';
  statusTextNotFound = 'Sorry, this page does not exist.';
  statusDescriptionNotFound =
    'The page might have been removed, had its name changed or is temporarily unavailable.';

  constructor(
    private router: Router,
    private navigationService: NavigationService,
  ) {}

  actionItems = [
    {
      name: 'Home',
      key: 'home',
    },
    {
      name: 'Contact Us',
      key: 'contact',
    },
  ];

  clickHandler(key: string): void {
    if (key === 'home') {
      this.navigationService.navigateToHome();
    } else if (key === 'contact') {
      this.navigationService.navigateToContact();
    } else {
      console.error('Key not recognised');
    }
  }
}
