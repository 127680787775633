import { Routes } from '@angular/router';

export const requestRoutes: Routes = [
  {
    path: '',
    redirectTo: 'send',
    pathMatch: 'full',
  },
  {
    path: 'send',
    loadComponent: () => import('./send-request').then(m => m.SendRequestComponent),
  },
];
