import { Environment } from '@ev-portals/dp/frontend/shared/util';

import { localEnvironment } from './environment.local';

export const environment: Environment = {
  ...localEnvironment,
  production: false,
  environment: 'dev',
  apiMockData: false,
  authClientId: '21aa160a-4891-4046-afd2-072f2a6590ad',
  baseUrl: 'https://dev.store.basf.com',
  apiBaseUrl: 'https://dev.store.basf.com',
  // appInsights: {
  //   instrumentationKey: 'dc465359-7d1d-4dcc-833a-590a995f5d60',
  // },
};
