import { environment } from '../../environments/environment';

export class ScriptInjector {
  static WINDOW = window as any;

  public static injectTealiumScript(): void {
    // Disable auto-tracking in Piano Analytics - based on the docs
    this.WINDOW.utag_cfg_ovrd = this.WINDOW.utag_cfg_ovrd || {};
    this.WINDOW.utag_cfg_ovrd.noview = true;

    // It can be dev/qa/prod
    const env = ['qa', 'prod'].includes(environment.environment) ? environment.environment : 'dev';

    // Prepare script
    const scriptContent = `
      var utag_data = {};

      (function(a,b,c,d){
        a='https://tags.tiqcdn.com/utag/basf/basfshop/${env}/utag.js';
        b=document;
        c='script';
        d=b.createElement(c);
        d.src=a;
        d.type='text/java'+c;
        d.async=true;
        a=b.getElementsByTagName(c)[0];
        a.parentNode.insertBefore(d,a);
      })();
    `;

    // Inject
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.id = 'tracking-script';
    scriptTag.textContent = scriptContent;
    document.head.appendChild(scriptTag);

    /**
     * Injects code for handling cookies and tracking, like this:
     *
     * <script type="text/javascript">
        (function(a,b,c,d){
        a='https://tags.tiqcdn.com/utag/basf/basfshop/dev/utag.js';
        b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
        a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
        })();
      </script>
     */
  }

  public static injectNpsScript(): void {
    // Inject
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.id = 'nps-script';
    scriptTag.src = 'https://resources.digital-cloud.medallia.eu/wdceu/421620/onsite/embed.js';
    scriptTag.async = true;
    document.head.appendChild(scriptTag);

    // <script type="text/javascript" src="https://resources.digital-cloud.medallia.eu/wdceu/421620/onsite/embed.js" async></script>
  }
}
