import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  AuthenticationService,
  FederationService,
} from '@ev-portals/dp/frontend/shared/auth/data-access';
import { NavigationService } from '@ev-portals/dp/frontend/shared/util';
import { DialogService } from '@ev-portals/ev/frontend/util';

/**
 * We'll get to this page after authenticating with our credentials on the login page of the Federation Service
 * We should get an authentication query code from the URL and send it to the backend to get authenticated
 */

@Component({
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoginComponent implements OnInit {
  #route = inject(ActivatedRoute);
  #federationService = inject(FederationService);
  #authService = inject(AuthenticationService);
  #navigationService = inject(NavigationService);
  #dialogService = inject(DialogService);

  ngOnInit(): void {
    this.#authenticateUser();
  }

  async #authenticateUser(): Promise<void> {
    const code = this.#route.snapshot.queryParamMap.get('code');
    if (!code) {
      this.#federationService.redirectToLogin();
    } else {
      this.#sendAuthCodeToBackend(code);
    }
  }

  #sendAuthCodeToBackend(authCode: string): void {
    this.#authService.login(authCode).subscribe({
      next: () => {
        this.#navigationService.navigateToOriginalDestination();
      },
      error: (err: any) => {
        // If an error occurs with 403 status, user is not allowed to access the portal -> Do not try again.
        // Auth Interceptor automatically redirects to forbidden screen.
        // For other errors (4xx, 5xx) try again.
        console.log('Error from access_token endpoint: ' + JSON.stringify(err));
        if (err.status != 403) {
          this.#dialogService
            .alert("Sorry, we couldn't log you in, please try again later.")
            .then(() => {
              this.#navigationService.navigateToHome();
            });
        }
      },
      complete: () => {
        console.log('# Request Login Complete');
      },
    });
  }
}
