import { Routes } from '@angular/router';

export const infoRoutes: Routes = [
  {
    path: 'contact',
    loadComponent: () => import('./contact').then(m => m.ContactComponent),
  },
  {
    path: 'privacy-policy',
    loadComponent: () => import('./privacy-policy').then(m => m.PrivacyPolicyComponent),
  },
  {
    path: 'terms-and-conditions',
    loadComponent: () => import('./terms-and-conditions').then(m => m.TermsAndConditionsComponent),
  },
  {
    path: 'terms-of-use',
    loadComponent: () => import('./terms-of-use').then(m => m.TermsOfUseComponent),
  },
];
