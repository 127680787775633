import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AngularModule } from '@atoms/angular';
import { VersionApiService } from '@ev-portals/dp/frontend/shared/api-client';
import { AuthenticationService } from '@ev-portals/dp/frontend/shared/auth/data-access';
import { LogoutIframeComponent } from '@ev-portals/dp/frontend/shared/auth/feature-logout';
import { CartProxyService } from '@ev-portals/dp/frontend/shared/data-access';
import { MenuName, NavigationService } from '@ev-portals/dp/frontend/shared/util';
import {
  NotificationComponent,
  ScrollTopButtonComponent,
} from '@ev-portals/ev/frontend/ui-library';
import { LayoutService } from '@ev-portals/ev/frontend/util';
import { map, Observable, of } from 'rxjs';

import { DynamicContentComponent } from './dynamic-content';
import { FooterComponent } from './footer';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FooterComponent,
    ScrollTopButtonComponent,
    NotificationComponent,
    AngularModule,
    DynamicContentComponent,
    LogoutIframeComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent implements AfterViewInit {
  #authenticationService = inject(AuthenticationService);
  #navigationService = inject(NavigationService);
  #layoutService = inject(LayoutService);
  // TODO: Add later
  #versionApiService = inject(VersionApiService);
  #cartProxyService = inject(CartProxyService);
  // private announcementService = inject(AnnouncementService);
  // private introTourService = inject(IntroTourService);

  activeMenuName$ = this.#navigationService.activeMenuName$ as Observable<MenuName>;
  loading = false;
  isMobileView$ = this.#layoutService.isMobileView$;
  isUserMenuOpen = false;
  isUserMenuActive = false;
  isLocaleMenuOpen = false;
  isLocaleMenuActive = false;
  user$ = this.#authenticationService.user$;
  apiHash$ = this.#versionApiService.getVersion();
  // public announcement$ = this.announcementService.announcement$;
  numberOfAddedCartItems$ = this.#cartProxyService.numberOfAddedCartItems$;
  tourIsInProgress = false;

  #userMenuItems: UserMenuItem[] = [
    // {
    //   id: 'myProfile',
    //   title: `My Profile`,
    //   clickHandler: (): void => {
    //     this.#navigationService.navigateToMyProfile();
    //     this.#closeSideMenu();
    //   },
    //   active$: this.activeMenuName$.pipe(
    //     map((name) => name === 'user-profile')
    //   ),
    //   classes: [],
    // },
    {
      id: 'userAdmin',
      title: `User Management`,
      clickHandler: (): void => {
        this.#navigationService.navigateToUserAdmin();
        this.#closeSideMenu();
      },
      active$: this.activeMenuName$.pipe(map(name => name === 'user-admin')),
      classes: [],
    },
    {
      id: 'productAdmin',
      title: `Product Management`,
      clickHandler: (): void => {
        this.#navigationService.navigateToProductAdmin();
        this.#closeSideMenu();
      },
      active$: this.activeMenuName$.pipe(map(name => name === 'product-admin')),
      classes: [],
    },
    {
      id: 'orderAdmin',
      title: `Order Management`,
      clickHandler: (): void => {
        this.#navigationService.navigateToOrderAdmin();
        this.#closeSideMenu();
      },
      active$: this.activeMenuName$.pipe(map(name => name === 'order-admin')),
      classes: [],
    },
    {
      id: 'logout',
      title: `Logout`,
      clickHandler: (): void => this.onLogoutAttempt(),
      active$: of(false),
      classes: ['font-bold', 'user-logout-menu', 'cursor-pointer', 'flex', 'text-black'],
    },
  ];
  userMenuItems: UserMenuItem[] = this.#userMenuItems;

  ngAfterViewInit(): void {
    this.#addLinkToHeaderTitle();
    this.#filterAvailableUserMenuItems();
  }

  #filterAvailableUserMenuItems(): void {
    this.#authenticationService.isAdmin$.subscribe(isAdmin => {
      this.userMenuItems = this.#userMenuItems.filter(item => {
        switch (item.id) {
          case 'userAdmin':
          case 'productAdmin':
          case 'orderAdmin':
            return isAdmin;
          default:
            return true;
        }
      });
    });
  }

  // Link the title in the header to the home page
  #addLinkToHeaderTitle(): void {
    setTimeout(() => {
      const atomHeader = document.querySelector('atom-header');
      const titleContent: HTMLElement | null =
        atomHeader?.shadowRoot?.querySelector('.title-content') ?? null;

      if (titleContent) {
        titleContent.style.cursor = 'pointer';
        titleContent.addEventListener('click', () => {
          this.#navigationService.navigateToProducts();
        });
      }
    }, 500);
  }

  onLogoutAttempt(): void {
    this.#authenticationService.logout().subscribe(this.#authenticationService.logoutHandler);
  }

  onClickProducts(): void {
    this.#navigationService.navigateToProducts();
    this.#closeSideMenu();
  }

  onClickRequests(): void {
    this.#navigationService.navigateToRequests();
    this.#closeSideMenu();
  }

  onClickCart(): void {
    this.#navigationService.navigateToCart();
    this.#closeSideMenu();
  }

  #closeSideMenu(): void {
    this.isUserMenuOpen = false;
    this.isLocaleMenuOpen = false;
    (document.querySelector('atom-navigation') as any).close();
  }

  openUserMenu(): void {
    this.isUserMenuOpen = true;
  }

  closeUserMenu(): void {
    this.isUserMenuOpen = false;
  }

  toggleUserMenu(): void {
    this.isUserMenuActive = !this.isUserMenuActive;
  }
}

export interface UserMenuItem {
  id: string;
  title: string;
  clickHandler: () => void;
  active$: Observable<boolean>;
  classes: string[];
}
