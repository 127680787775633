<atom-shell navigationTop>
  <!-- DESKTOP -->
  <atom-header title="myDistributorPortal" class="header-title" slot="header">
    <div class="header-items">
      <div class="flex items-center h-full">
        @if (user$ | async; as user) {
          <div
            class="cart-menu-link link cursor-pointer flex items-center mr-8"
            (click)="onClickCart()"
          >
            <atom-header-item
              icon="atom:action:shopping_cart"
              text=""
            ></atom-header-item>
            @if (numberOfAddedCartItems$ | async; as counter) {
              <span class="cart-count">
                {{ counter }}
              </span>
            }
          </div>
          <div
            class="profile-menu-link flex items-center link cursor-pointer h-full"
            (mouseenter)="openUserMenu()"
            (mouseleave)="closeUserMenu()"
          >
            @if ((isMobileView$ | async) === false) {
              <div
                class="floating-menu-container flex items-center relative h-[48px]"
              >
                <atom-sub-navigation-items
                  class="profile-menu-options floating-menu"
                  [hidden]="!isUserMenuOpen"
                >
                  @for (userMenuItem of userMenuItems; track userMenuItem) {
                    <atom-sub-navigation-item
                      [ngClass]="userMenuItem.classes"
                      (click)="userMenuItem.clickHandler()"
                      [active]="userMenuItem.active$ | async"
                    >
                      <span>{{ userMenuItem.title }}</span>
                    </atom-sub-navigation-item>
                  }
                </atom-sub-navigation-items>
              </div>
            }

            <div
              class="user-menu-link flex items-center"
              [ngClass]="
                (isMobileView$ | async)
                  ? 'cursor-default'
                  : 'link cursor-pointer'
              "
            >
              <atom-header-item
                data-cy="logged-in-user-icon"
                icon="atom:core:user"
                [ngStyle]="{
                  cursor: (isMobileView$ | async) ? 'default' : 'pointer',
                }"
                text=""
              ></atom-header-item>
              @if (user$ | async; as user) {
                <span data-cy="logged-in-user" class="user-name-container">
                  {{ user.firstName }} {{ user.lastName }}
                </span>
              }
              @if ((isMobileView$ | async) === false) {
                <atom-header-item
                  data-cy="user-menu"
                  icon="atom:core:arrow_down"
                  [ngStyle]="{
                    cursor: (isMobileView$ | async) ? 'default' : 'pointer',
                  }"
                  text=""
                ></atom-header-item>
              }
            </div>
          </div>
        } @else {
          <div class="flex items-center link cursor-pointer">
            <atom-header-item icon="atom:core:user" text=""></atom-header-item>
            <span [routerLink]="['/login']" data-cy="login-link">Login</span>
          </div>
        }
      </div>
    </div>
  </atom-header>

  <!-- SubHeader bar, which appears as a sidebar on mobile -->
  <atom-navigation id="navigation" slot="navigation">
    <atom-navigation-item
      class="products-menu-item"
      data-cy="products-menu"
      [active]="(activeMenuName$ | async) === 'products'"
      (click)="onClickProducts()"
    >
      <span class="link products-menu-link" slot="title">Products</span>
      <span class="link icon-link" slot="icon">
        <atom-icon icon-id="atom:basf:search"></atom-icon>
      </span>
    </atom-navigation-item>

    <atom-navigation-item
      class="requests-menu-item"
      data-cy="requests-menu"
      [active]="(activeMenuName$ | async) === 'requests'"
      (click)="onClickRequests()"
    >
      <span class="link requests-menu-link" slot="title">Requests</span>
      <span class="link icon-link" slot="icon">
        <atom-icon icon-id="atom:basf:send"></atom-icon>
      </span>
    </atom-navigation-item>

    @if ((isMobileView$ | async) && (user$ | async)) {
      <atom-navigation-item
        class="profile-menu-item flex items-center"
        (click)="toggleUserMenu()"
        [active]="isUserMenuActive"
      >
        <span class="link" slot="title">User Menu</span>
        <span class="link icon-link" slot="icon">
          <atom-icon icon-id="atom:core:user"></atom-icon>
        </span>
        <atom-sub-navigation-items>
          @for (userMenuItem of userMenuItems; track userMenuItem) {
            <atom-sub-navigation-item
              [ngClass]="userMenuItem.classes"
              (click)="userMenuItem.clickHandler()"
              [active]="userMenuItem.active$ | async"
            >
              <span>{{ userMenuItem.title }}</span>
            </atom-sub-navigation-item>
          }
        </atom-sub-navigation-items>
      </atom-navigation-item>
    }

    <dp-logout-iframe></dp-logout-iframe>
  </atom-navigation>

  <div slot="content">
    <dp-dynamic-content></dp-dynamic-content>
    <ev-scroll-top-button></ev-scroll-top-button>
    <router-outlet></router-outlet>
  </div>
  <dp-footer
    slot="footer"
    class="mt-4"
    [apiHash]="(apiHash$ | async)?.hash"
  ></dp-footer>
</atom-shell>
