<atom-footer mode="dark">
  <div slot="first"><span>Copyright</span> © BASF SE {{ currentYear }} </div>

  <!-- Controlled by the OneTrust team, the special class 'optanon-toggle-display' triggers it. Won't work on localhost -->
  <a slot="last" class="optanon-toggle-display cursor-pointer"
    >Cookie Settings</a
  >
  <a
    href="https://www.basf.com/global/en/legal/disclaimer.html"
    target="_blank"
    slot="last"
    data-cy="disclaimer"
    >Disclaimer</a
  >
  <a
    routerLink="/info/privacy-policy"
    href=""
    target="_blank"
    slot="last"
    data-cy="privacy-policy"
    >Privacy Policy</a
  >
  <a
    href=""
    target="_blank"
    routerLink="/info/terms-of-use"
    slot="last"
    data-cy="terms-of-use"
    >Terms of use</a
  >
  <a
    href="https://www.basf.com/global/en/legal/credits.html"
    target="_blank"
    slot="last"
    data-cy="imprint"
    >Imprint</a
  >
  <a href="" slot="last" routerLink="/info/contact" data-cy="contact-us"
    >Contact Us
  </a>
</atom-footer>

<!--atom-footer could not handle a long first slot hence the need for a new div for the versions-->
@if (stage !== "prod") {
  <div class="version p-2 text-sm font-medium">
    {{ appVersion }} | fe: {{ gitHash }} | api: {{ apiHash }}
  </div>
}
