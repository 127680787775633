import { Directive, inject } from '@angular/core';
import { NavigationService } from '@ev-portals/dp/frontend/shared/util';

@Directive()
export class ErrorPageDefaultsDirective {
  // TODO: add later
  // private authenticationService = inject(AuthenticationService);
  #navigationService = inject(NavigationService);

  actionItems = [
    {
      name: 'Logout',
      key: 'logout',
    },
    {
      name: 'Contact Us',
      key: 'contact',
    },
  ];

  clickHandler(key: string): void {
    if (key === 'logout') {
      // TODO: add later
      // this.authenticationService
      //   .logout()
      //   .subscribe(this.authenticationService.logoutHandler);
    } else if (key === 'contact') {
      this.#navigationService.navigateToContact();
    } else {
      console.error('Key not recognised');
    }
  }
}
