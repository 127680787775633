import { Component } from '@angular/core';
import { ErrorPageComponent } from '@ev-portals/ev/frontend/ui-library';

import { ErrorPageDefaultsDirective } from '../error-page-defaults.directive';

@Component({
  standalone: true,
  imports: [ErrorPageComponent],
  templateUrl: './page-forbidden.component.html',
  styleUrls: [],
})
export class PageForbiddenComponent extends ErrorPageDefaultsDirective {
  statusForbidden = '403 Forbidden.';
  statusTextForbidden = 'You are not allowed to access this resource.';
  statusDescForbidden = 'Please contact your system administrator for access.';
}
