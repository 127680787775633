import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartFacade } from '@ev-portals/dp/frontend/purchase/data-access';
import { AuthenticationService } from '@ev-portals/dp/frontend/shared/auth/data-access';
import { AnalyticsService } from '@ev-portals/dp/frontend/shared/util';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'dp-portals-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // Init analytics service and auth service
  #analyticsServie = inject(AnalyticsService);
  #authService = inject(AuthenticationService);
  // Needs to be injected to keep cart in sync (other domain may add items to the cart)
  #cartFacade = inject(CartFacade);
}
