import { Route } from '@angular/router';
import { infoRoutes } from '@ev-portals/dp/frontend/info/feature';
import { productRoutes } from '@ev-portals/dp/frontend/product/feature';
import { cartRoutes } from '@ev-portals/dp/frontend/purchase/feature';
import { requestRoutes } from '@ev-portals/dp/frontend/request/feature';
import { UserGuard } from '@ev-portals/dp/frontend/shared/auth/data-access';
import { LoginComponent } from '@ev-portals/dp/frontend/shared/auth/feature-login';
import {
  BaseLayoutComponent,
  PageForbiddenComponent,
  PageNotFoundComponent,
} from '@ev-portals/dp/frontend/shared/feature';
import { userRoutes } from '@ev-portals/dp/frontend/user/feature';

export const appRoutes: Route[] = [
  {
    path: '',
    component: BaseLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'products',
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'products',
        children: productRoutes,
        canActivateChild: [UserGuard],
      },
      {
        path: 'requests',
        children: requestRoutes,
        canActivateChild: [UserGuard],
      },
      {
        path: 'cart',
        children: cartRoutes,
        canActivateChild: [UserGuard],
      },
      {
        path: 'user',
        children: userRoutes,
        canActivateChild: [UserGuard],
      },
      {
        path: 'info',
        children: infoRoutes,
      },
      {
        path: 'error',
        children: [
          {
            path: '403',
            component: PageForbiddenComponent,
          },
        ],
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
];
