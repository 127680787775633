import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FederationService } from '@ev-portals/dp/frontend/shared/auth/data-access';
import { SafePipe } from '@ev-portals/ev/frontend/util';

@Component({
  standalone: true,
  selector: 'dp-logout-iframe',
  imports: [CommonModule, SafePipe],
  templateUrl: './logout-iframe.component.html',
})
export class LogoutIframeComponent {
  logoutUrl = '';

  constructor(private federationService: FederationService) {
    this.federationService.logoutUrl$.subscribe(url => {
      this.logoutUrl = url;
    });
  }

  logoutLoaded(): void {
    if (this.federationService.logoutProcessRunning) {
      // finish logout process, clean iframe
      this.federationService.finishLogout();
    }
  }
}
