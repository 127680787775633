import { Routes } from '@angular/router';
import { adminGuard } from '@ev-portals/dp/frontend/shared/auth/data-access';

import { ProfileNavComponent } from './profile-nav';

export const userRoutes: Routes = [
  {
    path: '',
    redirectTo: 'profile',
    pathMatch: 'full',
  },
  {
    path: '',
    component: ProfileNavComponent,
    children: [
      {
        path: 'profile',
        loadComponent: () => import('./user-profile').then(m => m.UserProfileComponent),
      },
      {
        path: 'admin',
        canActivateChild: [adminGuard()],
        children: [
          {
            path: 'users',
            loadComponent: () => import('./user-admin').then(m => m.UserAdminComponent),
          },
          {
            path: 'products',
            loadComponent: () =>
              import('@ev-portals/dp/frontend/product/feature').then(m => m.ProductAdminComponent),
          },
          {
            path: 'orders',
            children: [
              {
                path: '',
                loadComponent: () =>
                  import('@ev-portals/dp/frontend/order/feature').then(m => m.OrderAdminComponent),
              },
              {
                path: ':orderId',
                loadComponent: () =>
                  import('@ev-portals/dp/frontend/order/feature').then(
                    m => m.OrderAdminDetailsComponent,
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];
